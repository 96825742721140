import { Typography } from "@mui/material";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import ReactMarkdown from "react-markdown";
import { PADDING } from "ui/theme";

import evAtHomePlanAndSmartChargerCopy from "../../compounds/static/evAtHomePlanAndSmartChargerCopy.json";
import evAtHomePlanCopy from "../../compounds/static/evAtHomePlanCopy.json";
import fuelBackAtHomePlanCopy from "../../compounds/static/fuelBackHomePlanCopy.json";
import { BulletPointList } from "../Lists";
import { EVSmartChargerPricingTable } from "../Tables";
import {
  DialogContent,
  DialogContentSection,
  DialogContentSubSection,
} from "./DialogContent";

const {
  evPlanTermsAndConditionsCopy,
  evPlanCharacteristicsCopy,
  evPlanYourElectricityPriceCopy,
  evPlanGeneralThingsCopy,
} = evAtHomePlanCopy;

const {
  evSmartChargerPlanEligibilityTermsCopy,
  evSmartChargerPlanInstallationRequirementsCopy,
} = evAtHomePlanAndSmartChargerCopy;

const {
  fuelPlanTermsAndConditionsCopy,
  fuelPlanGeneralThingsCopy,
  fuelPlanRewardsCopy,
  fuelPlanYourElectricityPriceCopy,
  fuelPlanMovingPropertyCopy,
} = fuelBackAtHomePlanCopy;

/** Fuel back home plan terms and conditions dialog content */
const FuelTCDialogContent: React.FC = () => (
  <DialogContent>
    <ReactMarkdown>{fuelPlanTermsAndConditionsCopy.content}</ReactMarkdown>

    <DialogContentSubSection heading={fuelPlanRewardsCopy.heading}>
      <Typography variant="body1">
        {fuelPlanRewardsCopy.paragraphOne}
      </Typography>
      <BulletPointList listItems={fuelPlanRewardsCopy.listItems} />
      <Typography variant="body1">
        {fuelPlanRewardsCopy.paragraphTwo}
      </Typography>
    </DialogContentSubSection>

    <DialogContentSection heading={fuelPlanYourElectricityPriceCopy.heading}>
      <Typography variant="body1">
        {fuelPlanYourElectricityPriceCopy.paragraphOne}
      </Typography>

      <DialogContentSubSection
        heading={fuelPlanYourElectricityPriceCopy.subSectionOneContent.heading}
      >
        <Typography variant="body1">
          {fuelPlanYourElectricityPriceCopy.subSectionOneContent.paragraphOne}
        </Typography>
        <BulletPointList
          listItems={
            fuelPlanYourElectricityPriceCopy.subSectionOneContent.listItems
          }
        />
        <ReactMarkdown>
          {fuelPlanYourElectricityPriceCopy.subSectionOneContent.paragraphTwo}
        </ReactMarkdown>
      </DialogContentSubSection>

      <DialogContentSubSection
        heading={fuelPlanYourElectricityPriceCopy.subSectionTwoContent.heading}
      >
        <BulletPointList
          listItems={
            fuelPlanYourElectricityPriceCopy.subSectionTwoContent.listItems
          }
        />
      </DialogContentSubSection>
    </DialogContentSection>

    <DialogContentSection heading={fuelPlanMovingPropertyCopy.heading}>
      <DialogContentSubSection
        heading={fuelPlanMovingPropertyCopy.subSectionOneContent.heading}
      >
        <Typography variant="body1">
          {fuelPlanMovingPropertyCopy.subSectionOneContent.paragraphOne}
        </Typography>
      </DialogContentSubSection>
    </DialogContentSection>

    <DialogContentSection heading={fuelPlanGeneralThingsCopy.heading}>
      <BulletPointList listItems={fuelPlanGeneralThingsCopy.listItems} />
    </DialogContentSection>
  </DialogContent>
);

/** EV at home plan terms and conditions dialog content */
const EVTCDialogContent: React.FC = () => (
  <DialogContent>
    <ReactMarkdown>{evPlanTermsAndConditionsCopy.content}</ReactMarkdown>
    <DialogContentSection heading={evPlanCharacteristicsCopy.heading}>
      <DialogContentSubSection
        heading={evPlanCharacteristicsCopy.subSectionOneContent.heading}
      >
        <ReactMarkdown>
          {evPlanCharacteristicsCopy.subSectionOneContent.content}
        </ReactMarkdown>
      </DialogContentSubSection>
      <DialogContentSubSection
        heading={evPlanCharacteristicsCopy.subSectionTwoContent.heading}
      >
        <ReactMarkdown>
          {evPlanCharacteristicsCopy.subSectionTwoContent.content}
        </ReactMarkdown>
      </DialogContentSubSection>
    </DialogContentSection>
    <DialogContentSection heading={evPlanYourElectricityPriceCopy.heading}>
      <ReactMarkdown>
        {evPlanYourElectricityPriceCopy.paragraphOne}
      </ReactMarkdown>
    </DialogContentSection>
    <DialogContentSection heading={evPlanGeneralThingsCopy.heading}>
      <BulletPointList listItems={evPlanGeneralThingsCopy.listItems} />
    </DialogContentSection>
  </DialogContent>
);

/** EV Smart Charger terms and eligibility dialog content  */
const EVSmartChargerDialogContent: React.FC<{ GSTInclusive: boolean }> = ({
  GSTInclusive,
}) => (
  <DialogContent>
    <ReactMarkdown>
      {evSmartChargerPlanEligibilityTermsCopy.content}
    </ReactMarkdown>

    <EVSmartChargerPricingTable GSTInclusive={GSTInclusive} />

    <DialogContentSubSection
      heading={evSmartChargerPlanInstallationRequirementsCopy.heading}
    >
      <Typography
        variant="subtitle2"
        sx={{ textDecoration: "underline", paddingTop: PADDING.medium }}
      >
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphOne.heading}
      </Typography>
      <Typography variant="body1">
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphOne.content}
      </Typography>
      <Typography variant="subtitle2" sx={{ textDecoration: "underline" }}>
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphTwo.heading}
      </Typography>
      <Typography variant="body1">
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphTwo.content}
      </Typography>
      <Typography variant="subtitle2" sx={{ textDecoration: "underline" }}>
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphThree.heading}
      </Typography>
      <Typography variant="body1">
        {evSmartChargerPlanInstallationRequirementsCopy.paragraphThree.content}
      </Typography>
    </DialogContentSubSection>
    <ReactMarkdown>
      {evSmartChargerPlanEligibilityTermsCopy.moreInformation}
    </ReactMarkdown>
  </DialogContent>
);

export { EVSmartChargerDialogContent, EVTCDialogContent, FuelTCDialogContent };
