import { apiClient, apiFlickClient } from "lib/api";
import { toKVPairString } from "lib/util";

import {
  AddressType,
  CreateSignUpPayload,
  GetBundleResponse,
  GetSignUpResponse,
  ProductName,
  RateCardResponse,
  SignUpResponse,
  UpdateSignUpPayload,
  VerifyCode,
  VerifyCodeResponse,
  VerifyPhoneStartPayload,
  VerifyPhoneStartResponse,
  WindcaveTransaction,
  ZElectricAccountPayload,
  ZElectricAccountResponse,
} from "./types";

export const zElectricCreateAccountApi = "/domains/electric/v1/user";

export const verifyPhoneStart = async (payload: VerifyPhoneStartPayload) => {
  const response = await apiClient.post<VerifyPhoneStartResponse>(
    "/v1/phone-verification/start",
    {
      PhoneNumber: payload.number,
      CountryCode: payload.countryCode,
    },
  );

  return response.data;
};

export const verifyPhoneCode = async (payload: VerifyCode) => {
  const response = await apiClient.post<VerifyCodeResponse>(
    "/v1/phone-verification/code",
    {
      id: payload.id,
      verificationCode: payload.code,
    },
  );

  return response.data;
};

export const validateAddress = async (payload: AddressType) => {
  const mapToQuery = {
    unit: payload.unit,
    number: payload.number,
    street: payload.street.replace(/\s+/g, "+"),
    suburb: payload.suburb,
    city: payload.city,
    postcode: payload.postcode,
    region: payload.region,
  };

  const params = toKVPairString(mapToQuery, "&");
  const response = await apiFlickClient.get<any[]>(
    `/public_website/supply_nodes?${params}`,
  );
  return response.data;
};

export const getSupplyNodeDetails = async (icp: string) => {
  const response = await apiFlickClient.get<any[]>(
    `/public_website/supply_nodes/${icp}`,
  );

  return response.data;
};

export const fetchRateCards = async (icp: string) => {
  const response = await apiFlickClient.get<RateCardResponse>(
    `/public_website/rate_cards?ea_icp_id=${icp}&brand=z_electric`,
  );

  return response.data;
};

export const getWindcaveAuthorisation = async (transactionId: string) => {
  const response = await apiFlickClient.get<WindcaveTransaction>(
    `/customer/credit_cards/authorisations/${transactionId}`,
  );
  return response.data;
};
export const createWindcaveTransaction = async (signUpId: string) => {
  const redirect_url = window.location.href;
  const response = await apiFlickClient.post<WindcaveTransaction>(
    `/customer/credit_cards/authorisations`,
    {
      redirect_url,
      brand: "z_electric",
      sign_up_id: signUpId,
    },
  );
  return response.data;
};

export const createUserFlickSignUp = async (payload: CreateSignUpPayload) => {
  const {
    addressDetails,
    creationSource,
    signUpState,
    promoCode,
    leadSource,
    selectedProduct,
    productMetadata,
    property,
  } = payload;
  const town =
    addressDetails.city !== "" ? addressDetails.city : addressDetails.suburb;
  const mapToPayload = {
    state: signUpState,
    // Product
    brand: "z_electric",
    product: selectedProduct,
    bundle_ref: "",
    usage_type: property.usageType,
    billing_period:
      selectedProduct === ProductName.Z_ELECTRIC_REWARDS
        ? "fortnightly"
        : "monthly",
    billing_period_offset_weeks: 0,
    // Property/Address
    ea_icp_id: addressDetails.icp,
    promotional_code: promoCode,
    lead_source: leadSource,
    creation_source: creationSource,
    address: {
      town,
      unit: addressDetails.unit,
      street: addressDetails.street,
      number: addressDetails.number,
      suburb: addressDetails.suburb,
      post_code: addressDetails.postcode,
    },
    product_metadata: {
      charger_install: productMetadata.includeEVcharger,
      charger_colour: productMetadata.evChargerColor,
    },
  };

  const response = await apiFlickClient.post<SignUpResponse>(
    "/customer/nz/sign_ups",
    mapToPayload,
  );

  return response.data;
};

export const updateUserFlickSignUp = async (payload: UpdateSignUpPayload) => {
  const {
    details,
    payment,
    property,
    addressDetails,
    checks,
    id,
    creationSource,
    signUpState,
    promoCode,
    leadSource,
    selectedProduct,
    productMetadata,
  } = payload;
  const town =
    addressDetails.city !== "" ? addressDetails.city : addressDetails.suburb;
  const mapToPayload = {
    id,
    state: signUpState,
    // User
    first_name: details.firstName,
    last_name: details.lastName,
    preferred_name: details.prefName,
    email: details.emailAddress,
    mobile_number: details.mobileNumber,
    birth_date: details.dob,
    // Product
    brand: "z_electric",
    product: selectedProduct,
    bundle_ref: "",
    usage_type: property.usageType,
    // Payment
    payment_method: payment.paymentType,
    bank_account_type: payment.bankAccountType,
    billing_period:
      selectedProduct === ProductName.Z_ELECTRIC_REWARDS
        ? "fortnightly"
        : "monthly",
    billing_period_offset_weeks: 0,
    credit_check_accepted: payment.creditCheckAccecpted,
    terms_accepted: checks.termsAccepted,
    marketing_accepted: checks.marketingAccepted,
    switch_notifications_accepted: checks.smsSwitchNotificationsAccepted,
    direct_debit: {
      account_name: payment.directDebit?.accountName,
      account_number: payment.directDebit?.accountNumber,
      particulars: payment.directDebit?.pariculars,
      code: payment.directDebit?.code,
      terms_accepted: payment.directDebit?.tncsAgreed,
      authorised: payment.directDebit?.authorityConfirmed,
    },
    // Property/Address
    switch_date: property.switchDate,
    move_in_date: property.moveDate,
    product_metadata: {
      charger_install: productMetadata.includeEVcharger,
      charger_colour: productMetadata.evChargerColor,
      dwelling_type: property.dwellingType,
    },
    medically_dependent: property.medicallyDependent,
    ea_icp_id: addressDetails.icp,
    promotional_code: promoCode,
    lead_source: leadSource,
    creation_source: creationSource,
    address: {
      town,
      unit: addressDetails.unit,
      street: addressDetails.street,
      number: addressDetails.number,
      suburb: addressDetails.suburb,
      post_code: addressDetails.postcode,
    },
  };

  const response = await apiFlickClient.put<SignUpResponse>(
    "/customer/nz/sign_ups",
    mapToPayload,
  );

  return response.data;
};

export const getUserFlickSignUp = async (payload: string) => {
  const response = await apiFlickClient.get<GetSignUpResponse>(
    `/customer/nz/sign_ups/${payload}`,
  );

  return response.data;
};

export const createZElectricAccount = async (
  payload: ZElectricAccountPayload,
) => {
  const response = await apiClient.put<ZElectricAccountResponse>(
    zElectricCreateAccountApi,
    payload,
  );

  return response.data;
};

export const getBundle = async (payload: string) => {
  const response = await apiFlickClient.get<GetBundleResponse>(
    `/rewards/bundles?include=rewards&promo_code=${payload}`,
  );

  return response.data;
};
