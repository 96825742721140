import type { PayloadActionCreator } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";

import {
  AddressType,
  GetBundleResponse,
  GetSignUpResponse,
  RateCard,
  SignUpResponse,
  SupplyAddresses,
  SupplyNode,
  VerificationPayload,
  VerifyCodeResponse,
  VerifyPhoneStartResponse,
  WindcaveAuthorisation,
  WindcaveTransaction,
} from "./types";

export type SagaAction<PP = void, FP = unknown, RP = Error> = {
  request: PayloadActionCreator<PP>;
  success: PayloadActionCreator<FP>;
  failure: PayloadActionCreator<RP>;
};

export const createSagaAction = <
  PendingPayload = void,
  FulfilledPayload = unknown,
  RejectedPayload = Error,
>(
  typePrefix: string,
): SagaAction<PendingPayload, FulfilledPayload, RejectedPayload> => {
  return {
    request: createAction<PendingPayload>(`${typePrefix}/request`),
    success: createAction<FulfilledPayload>(`${typePrefix}/success`),
    failure: createAction<RejectedPayload>(`${typePrefix}/failure`),
  };
};

// ------------------------Async Actions----------------------------
export const checkSuccessfulAuthorisationAction = createSagaAction<
  void,
  WindcaveAuthorisation
>("coreState/checkSuccessfulAuthorisation");
export const completeRegistrationAction = createSagaAction<void>(
  "coreState/completeRegistration",
);
export const createFlickSignUpAction = createSagaAction<
  void,
  SignUpResponse,
  string
>("coreState/createFlickSignUp");
export const createWindcaveTransactionAction = createSagaAction<
  void,
  WindcaveTransaction
>("coreState/createWindcaveTransaction");
export const fetchRateCardsAction = createSagaAction<void, RateCard[], Error>(
  "coreState/fetchRateCards",
);
export const getFlickSignUpAction = createSagaAction<
  string,
  GetSignUpResponse,
  string
>("coreState/getFlickSignUp");
export const setCampaignLitresAction = createSagaAction<
  void,
  GetBundleResponse,
  string
>("coreState/setCampaignLitres");
export const updateFlickSignUpAction = createSagaAction<
  void,
  SignUpResponse,
  string
>("coreState/updateFlickSignUp");
export const validateAddressAction = createSagaAction<
  AddressType,
  SupplyAddresses
>("coreState/validateAddress");
export const checkEligibilityForAddressAction = createSagaAction<SupplyNode>(
  "coreState/checkEligibilityForAddress",
);
export const validateICPAction = createSagaAction<string, SupplyAddresses>(
  "coreState/validateICP",
);
export const verifyCodeAction = createSagaAction<string, VerifyCodeResponse>(
  "coreState/verifyCode",
);
export const verifyMobileAction = createSagaAction<
  VerificationPayload,
  VerifyPhoneStartResponse
>("coreState/verifyMobile");
