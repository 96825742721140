import "./AddressFinder.css";

import React, { useEffect, useState } from "react";

import { TextInput } from "../StyledComponents";

/**
 * Subset of meta data returned by AddressFinder
 * Full list found here: https://addressfinder.nz/api/nz/address/metadata/
 */
export interface AddressMeta {
  id: string;
  street?: string;
  number?: string;
  suburb?: string;
  city?: string;
  postcode?: string;
  a?: string;
  unit_type?: string;
  unit_identifier?: string;
  alpha?: string;
  region?: string;
}

interface Props {
  onSelected(fullAddress: string, address: AddressMeta): void;
}

const ADDRESS_FINDER_SRC = "https://api.addressfinder.io/assets/v3/widget.js";
const ADDRESS_FINDER_ID = "address_finder";

/**
 * Wrapper for addressfinder widget
 * https://addressfinder.nz/docs/demos/
 * @param props
 */
export default function AddressFinder(props: Props) {
  const [sciptLoaded, setScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = ADDRESS_FINDER_SRC;
    script.async = true;
    script.addEventListener("load", () => {
      setScriptLoaded(true);
    });
    window.document.body.appendChild(script);

    return () => {
      window.document.body.removeChild(script);
    };
  }, []);

  //
  useEffect(() => {
    if (sciptLoaded) {
      // @ts-ignore
      const widget = new window.AddressFinder.Widget(
        document.getElementById(ADDRESS_FINDER_ID),
        process.env.REACT_APP_ADDRESS_FINDER_KEY,
        "NZ",
        {
          address_params: {
            post_box: "0", // Disables PO BOX
          },
          max_results: 5,
          manual_style: true,
          empty_content: "",
        },
      );
      widget.on &&
        widget.on(
          "address:select",
          (fullAddress: string, metaData: AddressMeta) => {
            props.onSelected(fullAddress, metaData);
          },
        );
      widget.on &&
        widget.on("results:empty", () => {
          console.log("empty");
        });
    }
  }, [sciptLoaded, props]);

  return (
    <TextInput
      inputProps={{ form: { autoComplete: "off" } }}
      id={ADDRESS_FINDER_ID}
      label="Start by typing your address"
      placeholder="e.g. 42 Smith Street..."
    />
  );
}
