import { RequestStatus } from "lib/types";

export interface CoreState {
  verifyId?: string;
  userId: string | null;
  flickUserId: string | null;
  windcaveSignUpId?: string;
  transactionId?: string;
  eligibleAddresses: SupplyAddresses;
  selectedAddress?: AddressType;
  selectedEligibilityType?: EligibilityType;
  rateCards?: RateCard[];

  user: User;

  // Request Status
  validateAddressRequestStatus: RequestStatus;
  verificationRequestStatus: RequestStatus;
  verifyCodeRequestStatus: RequestStatus;
  createFlickSignUpRequestStatus: RequestStatus;
  updateFlickSignUpRequestStatus: RequestStatus;
  getFlickSignUpRequestStatus: RequestStatus;
  setCampaignLitresStatus: RequestStatus;
  completeRegistrationRequestStatus: RequestStatus;
  windcaveTransactionRequestStatus: RequestStatus;
  windcaveAuthorisationRequestStatus: RequestStatus;
  rateCardsRequestStatus: RequestStatus;

  // Error
  flickAccountError?: string;

  campaigns: Campaign[];

  creationSource?: string;

  // partial sign up state for api submission
  signUpState: string;
  // track current form step for customers returning after partial sign ups
  currentFormStep: string;
  partialSignUpDialogOpen: boolean;

  promoCode?: string;
  leadSource?: LeadSourceOptions;
  selectedProduct: string;

  productMetadata: ProductMetadata;
}

export interface ProductMetadata {
  includeEVcharger: boolean;
  evChargerColor?: EvChargerColors;
}

export interface Campaign {
  campaignLitres: number;
}

// ------------------------------------------------------------------
// -------------------------- Address type --------------------------
// ------------------------------------------------------------------

export interface AddressType {
  number: string;
  street: string;
  suburb: string;
  city: string;
  postcode: string;
  fullAddress: string;
  unit?: string;
  icp?: string;
  region?: string;
}

export interface SupplyAddresses {
  data?: SupplyNode[];
  included?: AddressNode[];
  supply_nodes?: any[];
}

export interface AddressNode {
  id: string;
  type: string;
  attributes: {
    unit_identifier: string;
    number: string;
    street: string;
    suburb: string;
    city: string;
    region: string;
    building_name?: string;
    postcode?: string;
  };
}

export interface SelectAddressPayload {
  address: AddressType;
  eligibility?: EligibilityType;
}

export type EligibilityType =
  | "manual"
  | "upgrade"
  | "not_in_area"
  | "success"
  | "export"
  | "reject"
  | "reject_commercial";

export interface SupplyNode {
  kind: string;
  identifiers: { registry_id: string };
  address: {
    unit_identifier?: string;
    number: string;
    street: string;
    suburb: string;
    city: string;
    region: string;
    building_name?: string;
    postcode?: string;
  };
  network_geography?: string;
  location_classification?: string;
  network_provider?: string;
  price_category_code?: string;
}

export interface AddressValidated {
  eligibleAddresses: SupplyAddresses;
}

export interface checkEligibilityBeforeSetSelectedAddress {
  any;
}

export interface EligibilityUpdate {
  eligibility: EligibilityType;
}

export enum UsageType {
  STANDARD = "standard",
  LOW = "low",
}

export interface RateCard {
  usage_type: UsageType | "standard" | "low";
  location_classification: string;
  subscription_rates: Rates[];
  usage_rates: Rates[];
  timetables: Timetables[];
  product_code: ProductName | "z_ev" | "z_electric_rewards";
  generation_price_contract?: string;
}

export interface RateCardResponse {
  data: {
    id: string;
    type: string;
    attributes: RateCard;
  }[];
}
export interface Rates {
  unit_code: string | "kwh" | "day";
  currency_code?: "NZD";
  differentiated?: true;
  channels: {
    content_code: string | "UN" | "CN";
    period_of_availability: number;
    flow_direction: string | "export";
  }[];
  rates: {
    rate_code: string | "Offpeak" | "Peak";
    network?: string;
    metering?: string;
    retailer?: string;
    admin?: string;
    generation?: string;
    regulator?: string;
    transmission?: string;
    total?: string;
  }[];
}

export interface Shifts {
  start_at: string;
  end_at: string;
}

export interface Timetable {
  weekends: boolean;
  holidays: any;
  first_month: any;
  last_month: any;
  shifts: Shifts[];
}

export interface Timetables {
  rate_code: string | "Offpeak" | "Peak";
  timetable?: Timetable[];
  descriptions?: string[];
  share?: string;
  ratio?: string;
}

// ------------------------------------------------------------------
// -------------------------- Verification --------------------------
// ------------------------------------------------------------------

export interface VerificationPayload {
  email: string;
  phone: string;
}

export interface VerificationTypeResponse {
  type: VerificationType;
}

export interface VerifyPhoneStartPayload {
  number: string;
  countryCode: string;
}

export interface VerifyPhoneStartResponse {
  id: string;
}

export interface VerifySuccessResponse {
  type: VerificationType;
  id?: string;
}

export interface VerifyCode {
  code: string;
  id: string;
}

export interface VerifyEmailCode {
  code: string;
  email: string;
}

export interface VerifyCodeResponse {
  userId: string | null;
}

export interface UserLogin {
  verificationId: string;
  userId: string;
}

export interface UserRegistration {
  firstName: string;
  lastName: string;
  email: string;
  // regionId: number;
  siteId: number;
  verificationId: string;
  optInEmailMarketing: boolean;
}

export interface UserLoginResponse {
  token: string;
}

// ------------------------------------------------------------------
// ------------------------------ User ------------------------------
// ------------------------------------------------------------------

export interface User {
  details: UserDetails;
  rewards: UserRewards;
  property: UserProperty;
  payment: UserPayment;
  checks: UserChecks;
}

export interface UserChecks {
  termsAccepted: boolean;
  marketingAccepted: boolean;
  smsSwitchNotificationsAccepted: boolean;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  prefName: string;
  dob: string | null;
  mobileNumber: string;
  emailAddress: string;
}

export interface UserRewards {
  fuelType: FuelTypes;
}

export interface UserProperty {
  situation: SituationAtAddress;
  moveDate?: string;
  switchDate?: string;
  dwellingType: DwellingType;
  medicallyDependent: boolean | undefined;
  usageType?: UsageType | "low" | "standard";
}

// ------------------------------------------------------------------
// ------------------------------ Payment ---------------------------
// ------------------------------------------------------------------

export type PaymentType = "credit_card" | "direct_debit";
export type BankAccountType = "personal" | "joint_signatory";

export interface UserPayment {
  paymentType: PaymentType;
  creditCheckAccecpted: boolean;
  bankAccountType?: BankAccountType;
  directDebit?: {
    accountName: string;
    accountNumber: string;
    pariculars?: string;
    code?: string;
    tncsAgreed: boolean;
    authorityConfirmed: boolean;
  };
}

export interface WindcaveTransaction {
  data: {
    id: string;
    type: string;
    attributes: {
      url: string;
      sign_up_id: string;
    };
  };
}
export interface WindcaveAuthorisation {
  data: {
    id: string;
    type: string;
    attributes: {
      sign_up_id: string;
      result: "APPROVED" | string;
    };
  };
}

// ------------------------------------------------------------------
// ------------------------------ SignUp ----------------------------
// ------------------------------------------------------------------
export interface CreateSignUpPayload {
  id?: string;
  addressDetails: AddressType;
  creationSource?: string;
  signUpState: string;
  promoCode?: string;
  leadSource?: LeadSourceOptions;
  selectedProduct: ProductName;
  productMetadata: ProductMetadata;
  property: UserProperty;
}

export interface UpdateSignUpPayload extends Omit<User, "rewards"> {
  id?: string;
  addressDetails: AddressType;
  creationSource?: string;
  signUpState: string;
  promoCode?: string;
  leadSource?: LeadSourceOptions;
  selectedProduct: ProductName;
  productMetadata: ProductMetadata;
}

export interface SignUpPayload {
  id: string;
  uuid: string;
}

export interface GetSignUpResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      brand: string;
      started_at: string | null;
      completed_at: string | null;
      current_customer: string | null;
      first_name: string;
      last_name: string;
      preferred_name: string;
      primary_phone: string;
      date_of_birth: string;
      monthly_spend: string | null;
      usage_type: string;
      selected_product: string | null;
      lead_source: LeadSourceOptions;
      promo_code: string | null;
      suburb: string;
      city: string;
      unit: string;
      street_number: string;
      street: string;
      region: string | null;
      postcode: string;
      medical_dependency: boolean | null;
      dwelling_type: DwellingType;
      requested_switch_date: string | null;
      moving_in_date: string | null;
      icp: string;
    };
  };
}

export interface SignUpResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
    };
  };
}

export interface ZElectricAccountPayload {
  flickApplicationId: string;
  preferredFuelType: FuelTypes;
  verificationId: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ZElectricAccountResponse {}

// ------------------------------------------------------------------
// -------------------------- Reward related ------------------------
// ------------------------------------------------------------------

export interface PromoCookie {
  promoCode: string;
  bundles: Bundle[];
}

interface Bundle {
  productName: string;
  name: string;
  description: string;
  label: string;
  emailDescription: string[];
  id: string;
  type: string;
  rewards: Reward[];
}

interface Reward {
  name: string;
  label: string;
  description: string;
  value: string;
  id: string;
  type: string;
}

export interface GetBundleResponse {
  data: Bundle[];
  included: Reward[];
}

interface Bundle {
  id: string;
  type: string;
  attributes: {
    product_name: string;
    name: string;
    description: string;
    label: string;
    email_description: string[];
  };
  relationships: {
    rewards: {
      data: [
        {
          id: string;
          type: string;
        },
        {
          id: string;
          type: string;
        },
      ];
    };
  };
  links: {
    self: string;
  };
}

interface Reward {
  id: string;
  type: string;
  attributes: {
    name: string;
    label: string;
    description: string;
    value: string;
  };
  links: {
    self: string;
  };
}

// ------------------------------------------------------------------
// ------------------------------ Types -----------------------------
// ------------------------------------------------------------------

export type SignUpSteps = "address" | "details" | "payment" | "success";
export type VerificationType = "email" | "mobile";
export type FuelTypes = "regular" | "premium" | "diesel";
export type SituationAtAddress = "moved_in" | "switch_provider";

export enum DwellingType {
  SINGLE_DETACHED_HOUSE = "single-detatched house",
  SEMI_DETACHED_HOUSE = "semi-detatched house",
  TERRACE_HOUSE = "terrace house",
  FLAT_OR_APARTMENT = "flat or apartment",
  FARMHOUSE_OR_HOMESTEAD = "farmhouse or homestead",
  SINGLE_DETACHED_GARAGE = "single-detatched garage",
}

export enum ProductName {
  Z_ELECTRIC_REWARDS = "z_electric_rewards",
  Z_EV = "z_ev",
}

export enum MapProductNameToVehicleType {
  z_electric_rewards = "fuel",
  z_ev = "electric",
}

export enum CustomerFacingProductName {
  z_electric_rewards = "Fuel back home",
  z_ev = "EV at home",
}

export enum DetailsInputSteps {
  about_you = "about_you",
  rewards = "rewards",
  property = "property",
  none = "none",
}

export enum CustomerFacingProductByline {
  z_electric_rewards = "Power for your home and car.",
  z_ev = "A smart power plan designed around your EV.",
}

export enum PaymentTypes {
  CREDIT_CARD = "credit_card",
  DIRECT_DEBIT_PERSONAL = "direct_debit_personal",
  DIRECT_DEBIT_JOINT = "direct_debit_joint",
}

export enum EvChargerColors {
  snow = "snow",
  sand = "sand",
  stone = "stone",
  volcanic = "volcanic",
}

export enum LeadSourceOptions {
  web_search = "web_search",
  friend_or_family = "friend_or_family",
  social_media = "social_media",
  advertising = "advertising",
  news_story = "news_story",
  comparison_site = "comparison_site",
  im_a_previous_customer = "i'm_a_previous_customer",
  the_spinoff = "the_spinoff",
  other = "other",
}

export enum EvPlanRateCodes {
  night = "Night",
  free = "Free",
  day = "Day",
}

export enum DialogTypes {
  evTermsAndConditions = "EVTC",
  fuelTermsAndConditions = "FuelTC",
  evSmartChargerTerms = "EVSmartCharger",
}
