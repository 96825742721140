import {
  EligibilityType,
  FuelTypes,
  GetSignUpResponse,
} from "domain/core/types";

const ELIGIBILITY_SUCCESS = "urn:flick:eligibility:success";
const ELIGIBILITY_NOT_IN_AREA = "urn:flick:eligibility:not-in-area";
const ELIGIBILITY_REJECT = "urn:flick:eligibility:reject";
const ELIGIBILITY_MANUAL = "urn:flick:eligibility:manual";
const ELIGIBILITY_EXPORT = "urn:flick:eligibility:export";
const ELIGIBILITY_UPGRADE = "urn:flick:eligibility:upgrade";

export const ELIGIBILITY_TYPES_MAP: { [key: string]: EligibilityType } = {
  [ELIGIBILITY_EXPORT]: "export",
  [ELIGIBILITY_MANUAL]: "manual",
  [ELIGIBILITY_NOT_IN_AREA]: "not_in_area",
  [ELIGIBILITY_REJECT]: "reject",
  [ELIGIBILITY_SUCCESS]: "success",
  [ELIGIBILITY_UPGRADE]: "upgrade",
};

import TagManager from "react-gtm-module";
import store, { persistor } from "store/configureStore";

import { RESET_ACTION } from "./types";

export const toKVPairString = (obj: any, delimeter: string) =>
  Object.keys(obj)
    .filter((key) => !!obj[key])
    .map((key) => `${key}=${obj[key]}`)
    .join(delimeter);

interface AddressToFormat {
  number: string;
  street: string;
  suburb?: string;
  city?: string;
  postcode?: string;
  unit?: string;
  building_name?: string;
}
export const formatAddress = (address?: AddressToFormat) => {
  if (!address) return "";

  const { number, street, suburb, city, postcode, unit, building_name } =
    address;
  const formattedAddress: string[] = [
    `${building_name ? `${building_name} ` : ""}${
      unit ? `${unit}/` : ""
    }${number} ${street}`,
  ];
  if (suburb && suburb !== "") {
    formattedAddress.push(`, ${suburb}`);
  }
  if (city && city !== "") {
    formattedAddress.push(`, ${city}`);
  }
  if (postcode && postcode !== "") {
    formattedAddress.push(`, ${postcode}`);
  }

  return formattedAddress
    .join("")
    .toLowerCase()
    .replace(/\b[A-z]/g, (c) => c.toUpperCase());
};

export const trimNZMobileNumber = (number: string) =>
  number.replace(/(^64)|(^\+64)|(^0)/g, "");

export const formatNumber = (trimmedNumber: string) => `+64${trimmedNumber}`;

export const isValidNZNumber = (number: string) => {
  return trimNZMobileNumber(number).match(/^2([0-9]{7,9})$/g);
};

// Accurately round numbers to a certain number of decimal places.
// Floating precision numbers that end in a decimal place of 5 will not be rounded up by toFixed.
export const financiallyRound = (number: number, precision: number) => {
  // This is the equivalent of 10 to the power of the precision, like 10 to the power of 2 is 100.
  const base = 10 ** precision;
  return (Math.round(number * base) / base).toFixed(precision);
};

export const mapFuelToReadable = (fuel: FuelTypes) => {
  switch (fuel) {
    case "regular":
      return "Z91";
    case "premium":
      return "Z95";
    case "diesel":
      return "Z Diesel";
    default:
      return "";
  }
};

export const parseFlickError = (errorResponse?: {
  status?: number;
  data?: { errors?: string };
}) => {
  if (errorResponse?.status === 422) {
    if (errorResponse?.data?.errors?.includes("Promotional code")) {
      return "This promo code is invalid.";
    }
    if (errorResponse?.data?.errors?.includes("account number")) {
      return "Please double check your Direct Debit details above.";
    }
  }

  return "Something went wrong, please try again or click here to contact support.";
};

export const fetchPromoCookie = () => {
  const promoCookieRaw = document.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith("promo"))
    ?.split("=")[1];

  if (promoCookieRaw === undefined) {
    return undefined;
  }

  return JSON.parse(promoCookieRaw);
};

export const sendAnalyticsEvent = (eventName: string, data: object) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...data,
    },
  });
};

export const sendAnalyticsPageView = (pageTitle: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "page_view",
      page_title: pageTitle,
    },
  });
};

export const reportEligibility = (eligibility: EligibilityType) => {
  switch (eligibility) {
    case "success":
    case "manual":
    case "export":
      sendAnalyticsEvent("page_event", {
        category: "Bill estimate",
        action: "Address search",
        label: "Serviceable",
      });
      break;
    case "upgrade":
    case "not_in_area":
    case "reject":
      sendAnalyticsEvent("page_event", {
        category: "Bill estimate",
        action: "Address search",
        label: "Ineligible",
      });
      break;
    default:
      sendAnalyticsEvent("page_event", {
        category: "Bill estimate",
        action: "Address search",
        label: "Unknown",
      });
      break;
  }
};

export const determineFormStep = (response: GetSignUpResponse) => {
  const signUp = response.data.attributes;
  return signUp.requested_switch_date || signUp.moving_in_date
    ? "/payment"
    : "/details?step=rewards";
};

export const humanReadableUsagePeriod = (usagePeriodValue: number) => {
  switch (usagePeriodValue) {
    case 14:
      return "Fortnightly";
    case 7:
      return "Weekly";
    default:
      return "Monthly";
  }
};

export const resetStorage = () => {
  persistor.pause();
  persistor
    .flush()
    .then(() => {
      store.dispatch(RESET_ACTION);
      persistor.purge();
    })
    .then(() => {
      persistor.persist();
    });
};
